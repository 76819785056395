import React, { useEffect, useRef, useState } from 'react'
import canAutoPlay from 'can-autoplay'
import { track } from 'lib/whoami'
import VideoChapters from 'components/VideoChapters'
import { useLogger } from 'hooks'
import { catchError, leaveBreadcrumb } from 'lib/bugsnag'
import { storyDetailEvents, videoPlayerEvents } from 'lib/ga'
import omit from 'lodash/omit'
import { isMobileUserAgent } from './utils'
import { v4 as uuid } from 'uuid'
import videojs from 'video.js'
import ima from 'lib/ima'
import 'videojs-contrib-ads'
import 'videojs-ima'
import 'videojs-mux'

import { THEME } from 'utils'
import Loading from 'icons/Loading'

import type {
  StoryVideoSource,
  PlayerOptions,
  VideoJSPlayer,
  EnhancedPlayer,
  MuxVideoData,
  AdsRequestParams,
  IMASettings
} from 'api/types'

interface VideoJsPlayerComponentProps {
  options: PlayerOptions
  videoSource: StoryVideoSource
  id?: string
  muxData?: MuxVideoData
  adsDisabled?: boolean
  startTime?: number
  cuePoints?: any[]
  live?: boolean
  hideChapters?: boolean
  stickyPlayer?: boolean
  adsRequestParams?: AdsRequestParams
  onReady?: (player: VideoJSPlayer) => Promise<void>
  onSetupIMA?: () => Promise<IMASettings | undefined>
  playNextVideo?: () => void
}

function VideoJsPlayerComponent({
  videoSource,
  options,
  onReady,
  playNextVideo,
  stickyPlayer = false,
  ...props
}: VideoJsPlayerComponentProps) {
  const sessionId = useRef(uuid())
  const videoRef = useRef<any>('')
  const playerRef = useRef<VideoJSPlayer | null>(null)
  const [adsReady, setAdsReady] = useState(false)
  const [didAutoplay, setDidAutoplay] = useState(false)
  const [isReplayView, setIsReplayView] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const logger = useLogger('VideoPlayer')
  const [videoVolume, setVideoVolume] = useState(1)
  const autoplay = options?.autoplay ?? false

  const player = playerRef?.current
  const disableAds = () => props.live || props.adsDisabled || props.startTime || stickyPlayer

  async function handleChapterClick(chapter: any) {
    if (!player) return
    try {
      await player.play()
    } catch (e) {
      logger.error('Chapter Click Error: ', e)
    } finally {
      player.currentTime(chapter.time)
    }
  }

  async function handleAutoplay(player: EnhancedPlayer) {
    const autoplaySupported = await detectAutoplaySupport()

    if (autoplaySupported) {
      player.autoplay(true)
      player.play()
    } else {
      player.autoplay(true)
      player.muted(true)
      player.play()
    }
    setDidAutoplay(true)
  }

  async function detectAutoplaySupport() {
    return await canAutoPlay.video({ timeout: 100, muted: false }).then(({ result, error }) => {
      if (result === false) {
        console.warn('Error did occur: ', error)
      }
      return result
    })
  }

  async function onPlayerReady(_player: VideoJSPlayer) {
    setupPlayer(_player)
    try {
      await ima.loadScript()
      await setupIma(_player as EnhancedPlayer)
    } catch (e) {
      console.error('Error setting up ima', e)
      logger.warn('Ads disabled. IMA failed to load.', e)
      catchError(e)
    }
    setupVideoEvents(_player as EnhancedPlayer)
    _player.poster(videoSource.poster)
    _player.playsinline(true)
    _player.load()
    if (props.startTime) {
      _player.currentTime(props.startTime)
    }
    if (stickyPlayer || autoplay) {
      _player.muted(true)
    }
    _player.volume(props.live ? 0.5 : videoVolume)
  }

  function setupVideoEvents(player: EnhancedPlayer) {
    const label = `${videoSource.id} | ${videoSource.name}`
    const percentsPlayedInterval = 10
    const videoSourceString = options.src?.[0]?.src
    let percentsAlreadyTracked = [] as number[]

    const playerName = stickyPlayer ? 'Brightcove Player - Sticky' : 'Brightcove Player'
    player.on('qualityLevelChangeStart', (e: any) => {
      logger.log('### Quality Level Change', e)
      setTimeout(() => {
        player.play()
      }, 500)
    })

    player.on('loadedmetadata', () => {
      logger.log('### Loaded Metadata')
      if (!stickyPlayer) {
        videoPlayerEvents.load({ label, playerName })
      }
      // Chrome uses this
      if (props.live && videoSource.active_source) {
        player.currentTime(0)
      }
    })
    if (player?.mux) {
      player.mux.emit('videochange', props.muxData)
    }
    player.on('ready', () => {
      const playerDuration = player.duration()
      typeof playerDuration === 'number' && setDuration(Math.round(playerDuration))
    })
    player.on('pause', () => {
      logger.log('### Pause')
    })
    player.one('play', () => {
      logger.log('### First Play')
      if (!stickyPlayer) {
        storyDetailEvents.videoStarted()
        track('ContentStarted', { content: { title: name, type: 'Video', src: videoSourceString } })
        videoPlayerEvents.start({ label, playerName })
      }

      // Safari uses this
      if (props.live && videoSource.active_source) {
        player.currentTime(0)
      }
    })
    player.on('play', () => {
      logger.log('### Play')
      const playerTime = player.currentTime()
      const currentTime = typeof playerTime === 'number' && Math.round(playerTime)
      videoPlayerEvents.play({ label, value: currentTime, playerName })

      if (isReplayView) {
        setIsReplayView(false)
      }
    })
    player.on('pause', () => {
      logger.log('### Pause')
      const playerTime = player.currentTime()
      const currentTime = typeof playerTime === 'number' && Math.round(playerTime)
      const playerDuration = player.duration()
      const duration = typeof playerDuration === 'number' && Math.round(playerDuration)
      if (currentTime !== duration) {
        videoPlayerEvents.pause({ label, value: currentTime, playerName })
      }
    })
    player.on('ended', () => {
      logger.log('### Ended')
      storyDetailEvents.videoFinished()
      videoPlayerEvents.end({ label, playerName })
      track('ContentCompleted', { content: { title: name, src: videoSourceString, type: 'Video' } })

      if (!playNextVideo?.()) {
        setIsReplayView(true)
      }
    })
    player.on('timeupdate', () => {
      const playerTime = player.currentTime()
      const currentTime = typeof playerTime === 'number' && Math.round(playerTime)
      const playerDuration = player.duration()
      const duration = typeof playerDuration === 'number' && Math.round(playerDuration)
      const percentPlayed = currentTime && duration && Math.round((currentTime / duration) * 100)
      currentTime && setCurrentTime(currentTime)
      duration && setDuration(duration)
      for (let percent = 0, step = percentsPlayedInterval; percent <= 99; percent += step) {
        if (
          percentPlayed &&
          percentPlayed >= percent &&
          !percentsAlreadyTracked.includes(percent) &&
          percentPlayed !== 0
        ) {
          percentsAlreadyTracked.push(percent)
        }
      }
    })
    player.on('fullscreenchange', () => {
      if (player.isFullscreen()) {
        videoPlayerEvents.enterFullscreen({ label, playerName })
      } else {
        videoPlayerEvents.exitFullscreen({ label, playerName })
      }
    })
    player.on('volumechange', () => {
      const volume = player.muted() ? 0 : player.volume()
      volume && setVideoVolume(volume)
      videoPlayerEvents.volume({ label, value: volume, playerName })
    })
    player.on('ads-ad-started', (e: any) => {
      logger.log('### Pre-roll Started', e)
      storyDetailEvents.videoAdStarted()
    })
    player.on('ads-play', (e: any) => {
      logger.log('### Pre-roll Play', e)
    })
    player.on('ads-pause', (e: any) => {
      logger.log('### Pre-roll Pause', e)
    })
    player.on('ads-ad-ended', (e: any) => {
      logger.log('### Pre-roll Ended', e)
      storyDetailEvents.videoAdFinished()
    })
    player.on('ads-manager', (e: any) => {
      logger.log('### Ads Manager: ', e)
      player.ima.addEventListener(window.google.ima.AdEvent.Type.AD_METADATA, function (e: any) {
        logger.log('### Ad Metadata loaded: ', e)
        leaveBreadcrumb('Ad Metadata loaded', e, 'log')
      })
      player.ima.addEventListener(window.google.ima.AdEvent.Type.LOADED, function (e: any) {
        logger.log('### Ad loaded: ', e)
        leaveBreadcrumb('Ad loaded', e, 'log')
      })
      player.ima.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, async function (e: any) {
        try {
          logger.log('### Content Resumed: ', e)
        } catch (e) {
          catchError(e)
        }
      })
    })
    player.on('ads-loader', (e: any) => {
      logger.log('### Ads Loader: ', e)
    })
    player.on('ads-request', (e: any) => {
      const adsRequest = e.AdsRequest

      adsRequest.setAdWillAutoPlay(autoplay)
      adsRequest.setAdWillPlayMuted(autoplay)

      logger.log('### Ads Request: ', e)
    })
    player.on('adsready', async () => {
      if (!adsReady) setAdsReady(true)
    })
    player.on('ads-ad-started', (e: any) => {
      console.log('ad-started', e)
    })
  }

  function setupPlayer(_player: VideoJSPlayer) {
    _player.error = (e) => {
      if (e) {
        console.log('player error', e)
        logger.error('player error', e)
        catchError(e)
      }
      return null
    }
  }

  async function setupIma(_player: EnhancedPlayer) {
    if (disableAds() || ima.disabled()) {
      return
    }
    const adsRenderingSettings = {
      enablePreloading: true,
      restoreCustomPlaybackStateOnAdBreakComplete: true
    }
    const adsRequest = {
      contentDuration: (videoSource.duration ?? 0) / 1000,
      setContinuousPlayback: true,
      ...props?.adsRequestParams
    }

    const additionalIMASettings = await props?.onSetupIMA?.()
    const imaSettings = {
      adsRequest,
      adsRenderingSettings,
      debug: true,
      numRedirects: 8,
      vastLoadTimeout: 8000,
      vpaidMode: 'insecure',
      disableFlashAds: true,
      autoPlayAdBreaks: true,
      showVpaidControls: true,
      showControlsForJSAds: true,
      sessionId: sessionId.current,
      disableCustomPlaybackForIOS10Plus: true,
      ...additionalIMASettings
    }

    if (typeof _player.ima === 'function') {
      _player.ima(imaSettings)
    }

    if (isMobileUserAgent()) {
      _player.one('tap', function () {
        _player.ima.initializeAdDisplayContainer()
        _player.ima.requestAds()
        _player.play()
      })
    }
  }

  useEffect(() => {
    const player = playerRef?.current
    if (player && adsReady === true && player?.controls_ === false) {
      player.controls(true)
    }
  }, [adsReady])

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js')

      videoElement.classList.add('vjs-big-play-centered')
      videoElement.classList.add('videoPlayer__container')
      videoRef.current.appendChild(videoElement)

      const _player = (playerRef.current = videojs(
        videoElement,
        {
          plugins: {
            mux: {
              data: {
                player_name: stickyPlayer ? 'Barstool Web (Sticky)' : 'Barstool Web',
                player_autoplay: autoplay,
                env_key: process.env.MUX_DATA_KEY,
                ...(props?.muxData ?? {})
              },
              respectDoNotTrack: false
            }
          },
          controls: props?.live ?? false,
          playsinline: true,
          fluid: true,
          playbackRates: [0.5, 1, 1.5, 2],
          enableDocumentPictureInPicture: false,
          enableSmoothSeeking: true,
          aspectRatio: '16:9',
          preload: 'none',
          techOrder: ['chromecast', 'html5'],
          liveui: props.live ?? false,
          sources: videoSource.active_source ?? videoSource.sources,
          ...omit(options, 'autoplay')
        },
        async () => {
          videojs.log('player is ready')
          await onReady?.(_player)
          await onPlayerReady(_player)
        }
      ))
    }
  }, [videoRef, options])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    if (player && !player.isDisposed()) {
      player.dispose()
      playerRef.current = null
    }
  }, [videoSource.id])

  // Handle autoplay support onAdsReady
  useEffect(() => {
    const player = playerRef?.current
    if (player && autoplay && adsReady && !didAutoplay) {
      handleAutoplay(player as EnhancedPlayer)
    }
  }, [autoplay, adsReady])

  return (
    <>
      <div className='video-wrapper'>
        <div data-vjs-player>
          <div ref={videoRef} />
        </div>
        {!props?.live && player && !adsReady && (
          <div className='loading-container'>
            <Loading color='#fff' />
          </div>
        )}
      </div>
      {!props.hideChapters && (
        <VideoChapters
          videoSource={videoSource}
          onClick={handleChapterClick}
          currentTime={currentTime}
          duration={duration}
        />
      )}
      <style jsx>
        {`
          .video-wrapper {
            position: relative;
          }
          .loading-container {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        `}
      </style>
    </>
  )
}

export default VideoJsPlayerComponent
